import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login/:tenant',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes), RecaptchaV3Module ],
  exports: [ RouterModule ],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdPAREaAAAAAPDjF6ZnZDYJmmmdoabb31FDlhMJ' }]
})
export class LoginRoutingModule {
  static components = [ LoginComponent ];
}
