<!--@if (root && item.visible !== false) {-->
<!--  <div>-->
<!--    <span class="layout-menuitem-text">{{item.label}}</span>-->
<!--  </div>-->
<!--}-->
@if ((!item.routerLink || item.items) && item.visible !== false && !item.root) {
  <a [attr.href]="item.url" class="pointer"
     [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple>
    <i [ngClass]="item.icon" class="layout-menuitem-icon pointer"></i>
    <span class="layout-menuitem-text">{{item.label}}</span>
    @if (item.items) {
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
    }
  </a>
}
@if ((item.routerLink && !item.items) && item.visible !== false && !item.root) {
  <a  class="pointer"
     routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: !item.preventExact}"
     [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple>
    <i [ngClass]="item.icon" class="layout-menuitem-icon pointer"></i>
    <span class="layout-menuitem-text p-ml-2">{{item.label}}</span>
    @if (item.items) {
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
    }
  </a>
}
@if (item.visible !== false && item.root) {
  <div class="layout-menu-tooltip">
    <div class="layout-menu-tooltip-arrow"></div>
    <div class="layout-menu-tooltip-text">{{item.label}}</div>
  </div>
}
<!--@if (((item.items && root) || (item.items && active)) && item.visible !== false) {-->
<!--  <ul [@children]="root ? 'visible' : active ? 'visibleAnimated' : 'hiddenAnimated'">-->
<!--    @for (child of item.items; track child; let i = $index) {-->
<!--      <li app-menuitem [item]="child" [index]="i" [parentKey]="key" ></li>-->
<!--    }-->
<!--  </ul>-->
<!--}-->
