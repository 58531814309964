import {Inject, Injectable} from '@angular/core';
import { EnvironmentModel } from '../models/environments.model';
import { DOCUMENT } from '@angular/common';

declare function require(name: string): any;

@Injectable({
  providedIn: 'root',
})
export class EnvironmentsService {
  public _environment: EnvironmentModel;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  getEnvironment(routeHostName: string): any {
    return new Promise((resolve) => {
      let environmentLevel: string;
      const environmentName: string = 'ENGAGE';

      // engage
      const apiBaseUrlEngageDevelopmentV1: string = 'https://utsmart-development.azurewebsites.net';
      const apiBaseUrlEngageDevelopmentV2: string = 'https://api.dev.engageapp.net';
      const tenantLogoEngage = 'logo_main_black.png';

      // set default
      this._environment = {
        environmentName,
        multiTenant: false,
        tenantEnum: 'IAFF',
        tenantLogo: tenantLogoEngage,
        supportEmail: 'engagesupport@uniontrack.com',
        tenantName: 'iaff_web',
        apiBaseUrlV1: apiBaseUrlEngageDevelopmentV1,
        apiBaseUrlV2: 'https://api.dev.engageapp.net'
      };
      // apply hostname specifics
      console.log(routeHostName);
      switch (routeHostName) {
        case 'localhost':
        case 'dev' :
        case '192.168.0.170' :
        case 'dev.admin.engageapp.net/':
          environmentLevel = 'Dev';
          this._environment.apiBaseUrlV1 = apiBaseUrlEngageDevelopmentV1;
          this._environment.apiBaseUrlV2 = apiBaseUrlEngageDevelopmentV2;
          this._environment.tenantEnum = 'IAFF';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'UT Admin';
          break;
        // **************************************************
        case 'local-api-dev':
          this._environment.tenantEnum = 'IAFF';
          this._environment.tenantLogo = 'logo_main_black.png';
          this._environment.pageTitle = 'UT Admin';
          this._environment.apiBaseUrlV1 = 'http://localhost:49599';
          this._environment.apiBaseUrlV2 = 'http://localhost:5055';
          break;
      }


      resolve(this._environment);
    });
  }

}
