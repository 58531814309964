<div class="body-image "></div>
<div class="login-page">
  <div class="p-grid p-fluid full-height">
    <div class="col-md-4 col-sm-6 col-sx-12 left">
      <div class="p-grid p-fluid">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="image-container">
              <img src="assets/images/engage-logo-square4-white.png" width="60%" alt="">
            </div>
            <div class="text-container">
<!--              <b>Welcome</b>, We are glad to see you again!-->
              Lock It In & Rip the Knob Off!
            </div>
          </div>
      </div>
    </div>
    <div class="col-md-8 col-sm-6 col-sx-12">
<div class="login-card">
  <p-card>
    @if (!needHelp) {
      <h2>Log In to Your Account</h2>
      <form [formGroup]="lForm" class="login-form">
        <div class="p-grid p-fluid">
<!--          <div class="col-md-12 col-sm-12 col-xs-12">-->
<!--            <img src="assets/images/logo_main_black.png" width="100%" alt="">-->
<!--          </div>-->
          <div class="col-md-12 col-sm-12 col-xs-12">
            <p-divider></p-divider>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <label>Username (E-mail)<span class="req"> *</span></label>
            <input type="email" pInputText formControlName="name" (keyup.enter)="login()">
            @if (lForm.controls.name.invalid && lForm.controls.name.dirty) {
              <div class="p-error">
                @if (lForm.controls.name.errors?.required) {
                  <div>Username is required</div>
                }
                @if (lForm.controls.name.errors?.email) {
                  <div>Username must be a valid e-mail address</div>
                }
              </div>
            }
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
            <label>Password/Passphrase<span class="req"> *</span></label>
            <input [type]="passwordType" pInputText formControlName="password" (keyup.enter)="login()">
            @if (lForm.controls.password.invalid && lForm.controls.password.dirty) {
              <div class="p-error">
                @if (lForm.controls.password.errors?.required) {
                  <div>Password is required</div>
                }
                <!--                <div *ngIf="changePasswordForm.controls.password.errors?.pattern">Must contain at least one uppercase and lowercase letter, one number, one special character, and be at least 10 characters long</div>-->
                @if (lForm.controls.password.hasError('length')) {
                  <div>Must be at least 10 characters long</div>
                }
                @if (lForm.controls.password.hasError('uppercase')) {
                  <div>Must contain at least one uppercase letter (A-Z)</div>
                }
                @if (lForm.controls.password.hasError('lowercase')) {
                  <div>Must contain at least one lowercase letter (a-z)</div>
                }
                @if (lForm.controls.password.hasError('number')) {
                  <div>Must contain at least one number (0-9)</div>
                }
                @if (lForm.controls.password.hasError('special')) {
                  <div>Must contain at least one special character</div>
                }
                @if (lForm.controls.password.hasError('repeats')) {
                  <div>Must have no more than 2 identical characters in a row (999 not allowed)</div>
                }
                @if (lForm.controls.password.hasError('customValidator')) {
                  <div>No parts of your username</div>
                }
              </div>
            }
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 p-mt-2">
            <div class="checkbox-input">
              <p-checkbox class="checkbox" [binary]="true" formControlName="showPassword"></p-checkbox>
              <label class="p-ml-1 auto-width">Show Password</label>
            </div>
            @if (resetButton) {
              <div class="col-md-12 col-sm-12 col-xs-12 p-mt-3 text-align-center">
                @if (!isLoading) {
                  <button pButton type="button" label="SIGN IN" (click)="login()" [disabled]="isFormValid()" class="auto-width"></button>
                } @else {
                  <button pButton type="button" label="SIGN IN" [disabled]="true" class="auto-width">
                    <i class="pi pi-spin pi-spinner save-spinner"></i>
                  </button>
                }
              </div>
            }
          </div>
        </div>
      </form>
    }
  </p-card>
</div>
    </div>
    </div>
  </div>
<p-toast [baseZIndex]="16000"></p-toast>
