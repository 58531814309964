import {MessageService} from 'primeng/api';
import {UserProfileService} from '@services/user-profile.service';
import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class PhoneValidationUtility {
  constructor(private userProfileService: UserProfileService, private messageService: MessageService) {
  }

  public phoneValidation(phone): any {
    return new Promise((resolve, reject) => {
      this.userProfileService.mobilePhoneNumberCheck(phone)
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            if (res === false) {
              this.messageService.add({
                severity: 'error', summary: 'Error',
                detail: 'The phone number you provided is not a mobile number. Please enter a different number or change the type to receive future text messages. Thank you'
              });
            }
            resolve(res);
          }, error: (e) => {
            console.debug(e);
            reject(null);
          }
        });
    });
  }
}

