import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {UserAccountPreLoginModel} from '@app/models/user-account.model';
import {UserAccountService} from './user-account.service';

@Injectable({
  providedIn: 'root',
})
export class UserAccountDataService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  constructor(private userAccountService: UserAccountService) {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  addPlaceholder(data: any[]) {
    if (data[0] !== undefined && data[0].ID !== null) {
      data.unshift({
        ClientId: null,
        TenantName: '--'
      });
    }
    return data;
  }

  preLoginData(modelData: UserAccountPreLoginModel) {
    return new Promise((resolve, reject) => {
      this.userAccountService.preLogin(modelData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (res) => {
            res = this.addPlaceholder(res);
            resolve(res);
          }, error:
            (e) => {
              console.warn(e);
              reject(e);
            }
        });
    });
  }
}
