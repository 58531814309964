import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {SidenavService} from '@app/core/services/sidenave-service';
import {Router} from '@angular/router';
import {ContextService} from '@services/context.service';
import {TenantConfigurations} from '@shared/tenant-configurations/configurations';
import {takeUntil} from 'rxjs/operators';
import {InterfaceObjectsService} from '@services/interface-objects.service';
import {SharedImports} from '@shared/shared-imports';
import {MessageService} from 'primeng/api';
import {MenuComponent} from '@app/core/components/side-nav/menu.component';
import menuNoTenant from '../../../json/menu-no-tenant.json';
import menu from '../../../json/menu.json';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
   standalone: true,

  imports: [SharedImports, MenuComponent]
})
export class SideNavComponent implements OnInit, AfterViewInit {
  activeMenu: any[];
  setTenantId: number = 10000;
  activeItems: any[];
  // itemsSuperTrainingGroup: any[];
  loadingMenu: boolean;

  isUser: boolean = false;
  @Input() selectedMenuView: string;
  tenantConfigurations = TenantConfigurations;
  worksitesLabel = 'Worksites';
  private ngUnsubscribe = new Subject();

  constructor(private sidenavService: SidenavService, private router: Router,
              private contextService: ContextService, private interfaceObjectService: InterfaceObjectsService) {
  }


  ngOnInit(): void {

  }

  ngAfterViewInit() {

    if (!this.contextService.contextObject.selectedTenant) {
      this.activeMenu = menuNoTenant;
    }
  }

}
