import {AuxiliaryAuxFiltersModel} from './roster-query-primeng.model';

export class TableFilterModel {
  query: string;
  sort: string;
  page: number;
  pageSize: number;
  group: string;
  filter: string;
  aggregate: string;
}

export class PrimeTableFilterModel {
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters: Record<string, FilterModel[]>;
  multiSortMeta?: string;
  globalFilter?: string;
  globalOperator?: string;
  ExportColumns?: ExportColumnModel[];
  ExportFileType?: string;
  AuxFilters: AuxiliaryAuxFiltersModel[];
}

export class PrimeTableFilterForMailMergeModel {
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  filters: Record<string, FilterModel[]>;
  multiSortMeta?: string;
  globalFilter?: string;
  globalOperator?: string;
  ExportColumns?: ExportColumnModel[];
  ExportFileType?: string;
  AuxFilters: AuxiliaryAuxFiltersModel[];
}

export class PrimeTableFilterWithIDsModel {
  tableFilterModel: PrimeTableFilterModel;
  ids?: number[];
}

export interface FilterModel {
  matchMode: string;
  operator: string;
  value: any;
  endValue?: any;
}

export interface ExportColumnModel {
  FieldName: string;
  ColumnHeader: string;
}

