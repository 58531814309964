import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ContextService} from './context.service';

import {
  InterfaceObjectHelpGridModel, InterfaceObjectHelp,
  CreateUpdateInterfaceObjectHelpModel, CreateInterfaceObjectHelpFlyModel
} from '@app/models/interface-object-help.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class InterfaceObjectHelpService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getInterfaceObjectHelpKGrid(): Observable<InterfaceObjectHelpGridModel> {
    return this.http.get<InterfaceObjectHelpGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/help/grid`);
  }

  getInterfaceObjectHelpPrimeNG(dataMode: string, filterData): Observable<InterfaceObjectHelpGridModel> {
    Userpilot.track('Support');
    return this.http.post<InterfaceObjectHelpGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/help/grid?mode=${dataMode}`, filterData);
  }

  // getInterfaceObjectHelpByPageKGrid(interfaceObjectId: number): Observable<InterfaceObjectHelpGridModel> {
  //   return this.http.get<InterfaceObjectHelpGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/help/grid/bypage/profile?page=${interfaceObjectId}`);
  // }

  getInterfaceObjectHelpByPagePrimeNG(dataMode: string, interfaceObjectId: number, interfaceObjectEnum: string, filterData): Observable<InterfaceObjectHelpGridModel> {
    return this.http.post<InterfaceObjectHelpGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/help/grid/bypage/${interfaceObjectEnum}?page=${interfaceObjectId}&mode=${dataMode}`, filterData);
  }

  getInterfaceObjectHelp(interfaceHelpId: number): Observable<InterfaceObjectHelp> {
    return this.http.get<InterfaceObjectHelp>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/help/${interfaceHelpId}`);
  }

  createInterfaceObjectHelp(modelData: CreateUpdateInterfaceObjectHelpModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/help/create`, modelData);
  }

  createInterfaceObjectHelpCreateElement(modelData: CreateInterfaceObjectHelpFlyModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/help/create`, modelData);
  }

  updateInterfaceObjectHelp(interfaceHelpId: number, modelData: CreateUpdateInterfaceObjectHelpModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/help/${interfaceHelpId}`, modelData);
  }

  deleteInterfaceObjectHelp(interfaceHelpId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/help/remove/${interfaceHelpId}`);
  }
}
