import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from '@shared/guards/can-activate.guard';
import {CanDeactivateGuard} from '@services/can-deactivate-guard.service';
import {PreloadModulesStrategy} from './core/strategies/preload-modules.strategy';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/dashboard'},

  {path: 'login', data: {preload: true}, loadChildren: () => import('./login/login-routing.module').then(m => m.LoginRoutingModule)},

  {
    path: 'dashboard', canActivate: [AuthGuardService],
    loadChildren: () => import('./dashboard/dashboard-routing.module').then(m => m.DashboardRoutingModule)
  },
  {
    path: 'admin/tenants', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenants/tenants-routing.module').then(m => m.TenantsRoutingModule)
  },
  { path: 'tenant-users', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-users/tenant-users-routing.module').then(m => m.TenantUsersRoutingModule)
  },
  { path: 'admin/menus', canActivate: [AuthGuardService],
    loadChildren: () => import('./all-menus/all-menus-routing.module').then(m => m.AllMenusRoutingModule)
  },

  { path: 'admin/admin-users', canActivate: [AuthGuardService],
    loadChildren: () => import('./admin-users/admin-users-routing.module').then(m => m.AdminUsersRoutingModule)
  },

  { path: 'tenant-contacts', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-contacts/tenant-contacts-routing.module').then(m => m.TenantContactsRoutingModule)
  },

  { path: 'tenant-communication-usage', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-communication-usage/tenant-communication-usage-routing.module').then(m => m.TenantCommunicationUsageRoutingModule)
  },

  { path: 'tenant-menus', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-menus/tenant-menus-routing.module').then(m => m.TenantMenusRoutingModule)
  },

  { path: 'vendor-settings', canActivate: [AuthGuardService],
    loadChildren: () => import('./vendor-settings/vendor-settings-routing.module').then(m => m.VendorSettingsRoutingModule)
  },

  { path: 'tier', canActivate: [AuthGuardService],
    loadChildren: () => import('./tier/tier-routing.module').then(m => m.TierRoutingModule)
  },

  { path: 'branding', canActivate: [AuthGuardService],
    loadChildren: () => import('./branding/branding-routing.module').then(m => m.BrandingRoutingModule)
  },

  { path: 'tenant-dashboard', canActivate: [AuthGuardService],
    loadChildren: () => import('./tenant-dashboard/tenant-dashboard-routing.module').then(m => m.TenantDashboardRoutingModule) },

  { path: 'page-not-found', canActivate: [AuthGuardService],
    loadChildren: () => import('./page-not-found/page-not-found-routing.module').then(m => m.PageNotFoundRoutingModule) },

  { path: '**', pathMatch: 'full', redirectTo: '/page-not-found' } // catch any unfound routes and redirect to home page

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadModulesStrategy})],
  exports: [RouterModule],
  providers: [PreloadModulesStrategy, CanDeactivateGuard]
})
export class AppRoutingModule {
}
