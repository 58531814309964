import {Pipe, PipeTransform} from '@angular/core';
import {MessageService} from 'primeng/api';

@Pipe({
   standalone: true,
  name: 'indexOf'
})
export class IndexOfPipe implements PipeTransform {

  transform(value, searchString): number {
    if (typeof value === 'string' && typeof searchString === 'string') {
      return value.indexOf(searchString);
    }
    return -1;
  }
}
