import {enableProdMode, importProvidersFrom} from '@angular/core';
import {environment} from './environments/environment';
import {bootstrapApplication, BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from '@app/app-routing.module';
import {LoginRoutingModule} from '@app/login/login-routing.module';
import {SharedImports} from '@shared/shared-imports';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {SidebarModule} from 'primeng/sidebar';
import {AppComponent} from '@app/app.component';
import {MessageService} from 'primeng/api';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {providePrimeNG} from 'primeng/config';
import Aura from '@primeng/themes/aura';
import {MyPreset} from './mypreset';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, AppRoutingModule, LoginRoutingModule, SharedImports, SidebarModule, BrowserAnimationsModule),
    Title,
    MessageService,
    provideAnimations(),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: MyPreset,
        options: {
          prefix: 'p',
          darkModeSelector: false,
          cssLayer: false,
        },
      },
    })
  ]
})
  .catch(err => console.error(err));

//Initialize Userpilot
//Userpilot.initialize('NX-bd2bc21a'); //`appToken` should be replaced with your userpilot appToken.

