import {Injectable} from '@angular/core';

@Injectable()
export class DateRangePickerDefinitions {
  public dateRangeOptions: any[];

  constructor() {

    this.dateRangeOptions = [
      {option: '--', value: null},
      {option: 'Active', value: 'Active'},
      {option: 'Expired', value: 'Expired'},
      {option: 'Expires in 3 Months', value: 'Expires in 3 Months'},
      {option: 'Expires in 6 Months', value: 'Expires in 6 Months'},
      {option: 'Issued last 3 Months', value: 'Issued last 3 Months'},
      {option: 'Issued last 6 Months', value: 'Issued last 6 Months'},
    ];
  }
}
