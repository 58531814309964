<div class="menu-wrapper" (click)="onMenuClick($event)">
  <div class="layout-menu-container">
    <div class="p-fluid p-grid">
      <div class="col-md-12 col-sm-12 col-xs-12 tenant-selector">
        <p-select [options]="tenantList" (onChange)="setTenantData($event)" [(ngModel)]="setTenantId"
                  optionLabel="Description" optionValue="ID"></p-select>
      </div>
    </div>
  </div>
</div>

<ul class="layout-menu p-mt-2">
  <p-panelmenu [model]="activeMenu" styleClass="w-full md:w-80" [multiple]="true">
    <ng-template #item let-item let-index="index">
      <app-menuitem [item]="item" [index]="index" [root]="true" (click)="itemClick($event, item)"></app-menuitem>
    </ng-template>
  </p-panelmenu>

</ul>
