import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {ContextService} from '@services/context.service';

export const AuthGuardService: CanActivateFn = (route, state) => {
  const contextService = inject(ContextService);
  const router = inject(Router);

  const accessToken = contextService.contextObject.accessToken;
  if (accessToken && accessToken !== 'null') {
    if (!contextService.contextObject.UserAgreement && contextService.contextObject.tenantName === 'IUPAT' && state.url !== '/user-agreement') {
      router.navigate(['user-agreement']);
      return false;
    }
    return true;
  }
  // console.debug(route, state);
  // Track URL user is trying to go to, so we can send them there after logging in

  //this.authService.redirectUrl = state.url;
  router.navigate(['login']);
  return false;
};
