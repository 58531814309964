import {Injectable} from '@angular/core';
// import {ContextModel} from '../models/context.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  // context: ContextModel = this.contextObject;
  private contextDataPerspective$ = new BehaviorSubject<string>('');
  context$ = this.contextDataPerspective$.asObservable();

  set contextObject(value) {
    sessionStorage.setItem('contextObject', JSON.stringify(value));
  }

  get contextObject() {
    return JSON.parse(sessionStorage.getItem('contextObject'));
  }
}

export default class contextService {
}
