import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, NgOptimizedImage, PercentPipe} from '@angular/common';
import {CardModule} from 'primeng/card';
import {InputNumberModule} from 'primeng/inputnumber';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccordionModule} from 'primeng/accordion';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DividerModule} from 'primeng/divider';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MenubarModule} from 'primeng/menubar';
import {ListboxModule} from 'primeng/listbox';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {BadgeModule} from 'primeng/badge';
import {PanelModule} from 'primeng/panel';
import {ToolbarModule} from 'primeng/toolbar';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputMaskModule} from 'primeng/inputmask';
import {SliderModule} from 'primeng/slider';
import {PaginatorModule} from 'primeng/paginator';
import {CalendarModule} from 'primeng/calendar';
import {ToastModule} from 'primeng/toast';
import {TabViewModule} from 'primeng/tabview';
import {EditorModule} from '@tinymce/tinymce-angular';
import { MessageModule } from 'primeng/message';
import { PanelMenu } from 'primeng/panelmenu';


// Services
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {PickListModule} from 'primeng/picklist';
import {WebcamModule} from 'ngx-webcam';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {HighchartsChartModule} from 'highcharts-angular';
import {InputSwitchModule} from 'primeng/inputswitch';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { ScrollerModule } from 'primeng/scroller';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {GoogleMapsModule} from '@angular/google-maps';
import {TimelineModule} from 'primeng/timeline';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi, withJsonpSupport} from '@angular/common/http';
import {NgxPhotoEditorModule} from 'ngx-photo-editor';
import {TreeModule} from 'primeng/tree';
import {FocusTrapModule} from 'primeng/focustrap';
import {SelectButtonModule} from 'primeng/selectbutton';
import { SelectModule } from 'primeng/select';
import { InputTextModule } from 'primeng/inputtext';
import { TextareaModule } from 'primeng/textarea';

// Components
import {SelectComponent} from '@shared/components/select/select.component';
import {YesNoSelectComponent} from '@shared/components/select/yes-no-select/yes-no-select.component';
// Definitions
import {RosterDefinitions} from './definitions/roster.definition';
import {RosterStudentDefinitions} from './definitions/roster-student.definition';
import {ProspectDefinitions} from './definitions/prospects.definition';
import {DateRangePickerDefinitions} from './definitions/date-range-picker.definitions';
import {ExemptUsersDefinitions} from './definitions/exempt-users.definitions';
import {TenantFilterDefinitions} from './definitions/tenant-filter.definitions';


// Pipes
import {PhonePipe} from './pipes/phone.pipe';
import {ToFixedPipe} from './pipes/tofixed.pipe';
import {ToFixedCurrencyPipe} from './pipes/tofixed-currency.pipe';
import {CamelcaseSpacePipe} from './pipes/camelcase-space.pipe';
import {LocalizeDateTimePipe, ShortLocalizeDateTimePipe} from './pipes/localize-datetime.pipe';
import {StatePipe} from './pipes/state.pipe';
import {MatchTypeReplace} from './pipes/filter.pipes';
import {FirstLetter} from './pipes/first-letter.pipe';
import {YesNoPipe} from './pipes/boolean.pipe';
import {SafeHtmlPipe} from './pipes/html-sanitizer.pipte';

// Utilities
import {DialogUtilityComponent} from './utilities/dialog.utility';
import {PhoneValidationUtility} from './utilities/phone-validation.utility';
import {RouteContextUtility} from './utilities/route-context.utility';
import {ConfirmValidationUtility} from './utilities/confirm-validation.utility';
import {DialogModule} from 'primeng/dialog';

import {ConvertAuxFiltersUtility} from './utilities/convert-aux-filters.utility';
import {DateMaskDirective} from '@shared/directives/calendar-mask.component';
import {CurrencyInputComponent} from './components/currency-input/currency-input.component';
import {BooleanCheckboxComponent} from './components/boolean-checkbox/boolean-checkbox.component';
import {MultiSelectFilterListComponent} from './components/multi-select-filter-list/multi-select-filter-list.component';
import {NgxCurrencyDirective} from 'ngx-currency';
import {SnapshotDefinitions} from './definitions/snapshot.definitions';
import {MultiSelectLanguagesComponent} from './components/multi-select-languages/multi-select-languages.component';
import {MultiSelectComponent} from '@shared/components/multi-select/multi-select.component';
import {DataIndicatorComponent} from '@shared/components/data-indicator/data-indicator.component';
import {LearnMoreComponent} from '@shared/components/learn-more/learn-more.component';
import {MultiSelectUniversalComponent} from '@shared/components/multi-select-universal/multi-select-universal.component';
import {RecordNotFoundComponent} from '@shared/components/record-not-found/record-not-found.component';
import {UniversalDatePickerComponent} from '@shared/components/universal-date-picker/universal-date-picker.component';
import {TextEditorComponent} from '@shared/components/text-editor/text-editor.component';
import {InterfaceObjectHelpService} from '@services/interface-object-help.service';
import {InterfaceObjectsService} from '@services/interface-objects.service';
import {LookupsService} from '@services/lookups.service';
import {MessagesModule} from 'primeng/messages';
import {RouterModule} from '@angular/router';
import {TooltipModule} from 'primeng/tooltip';
import {SidenavService} from '@app/core/services/sidenave-service';
import {AlertsService} from '@app/core/services/alerts.service';
import {AuthService} from '@app/core/services/auth.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {HttpCacheService} from '@app/core/services/http-cache.service';
import {BlobStorageService} from '@app/core/services/blob-storage.service';
import {ApiInterceptor} from '@app/core/interceptors/api.interceptor';
import {StepsModule} from 'primeng/steps';
import {IndexOfPipe} from '@shared/pipes/indexOf.pipe';
import {GoogleRecaptchaService} from '@services/google-recaptcha.service';

@NgModule({ exports: [
    CommonModule,
    TextareaModule,
    InputTextModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CheckboxModule,
    ConfirmDialogModule,
    DividerModule,
    ButtonModule,
    SplitButtonModule,
    RadioButtonModule,
    MenubarModule,
    ListboxModule,
    TableModule,
    DropdownModule,
    ProgressSpinnerModule,
    BadgeModule,
    PanelModule,
    ToolbarModule,
    CardModule,
    MultiSelectModule,
    SliderModule,
    InputMaskModule,
    PaginatorModule,
    CalendarModule,
    PhonePipe,
    MatchTypeReplace,
    ToFixedPipe,
    StatePipe,
    FirstLetter,
    ToFixedCurrencyPipe,
    CamelcaseSpacePipe,
    YesNoPipe,
    SafeHtmlPipe,
    TabViewModule,
    ScrollerModule,
    FileUploadModule,
    PickListModule,
    WebcamModule,
    OverlayPanelModule,
    HighchartsChartModule,
    InputSwitchModule,
    AutoCompleteModule,
    SafeHtmlPipe,
    NgScrollbarModule,
    GoogleMapsModule,
    TimelineModule,
    TreeModule,
    FocusTrapModule,
    NgxPhotoEditorModule,
    NgOptimizedImage,
    SelectButtonModule,
    EditorModule,
    MultiSelectComponent,
    BooleanCheckboxComponent,
    CurrencyInputComponent,
    DataIndicatorComponent,
    LearnMoreComponent,
    MultiSelectFilterListComponent,
    MultiSelectLanguagesComponent,
    MultiSelectUniversalComponent,
    RecordNotFoundComponent,
    PanelMenu,
    SelectComponent,
    UniversalDatePickerComponent,
    TextEditorComponent,
    LocalizeDateTimePipe,
    BadgeModule, CommonModule, ToastModule, MessagesModule, RouterModule, AccordionModule,
    DropdownModule, FormsModule, ButtonModule, TooltipModule, NgOptimizedImage, AutoCompleteModule,
    StepsModule,
    DateMaskDirective,
    ShortLocalizeDateTimePipe,
    MessageModule,
    SelectModule,
    InputTextModule,
    YesNoSelectComponent
],
declarations: [
    PhonePipe,
    MatchTypeReplace,
    ToFixedPipe,
    ToFixedCurrencyPipe,
    CamelcaseSpacePipe,
    YesNoPipe,
    StatePipe,
    SafeHtmlPipe,
    FirstLetter,
    SelectComponent,
    MultiSelectComponent,
    BooleanCheckboxComponent,
    CurrencyInputComponent,
    DataIndicatorComponent,
    LearnMoreComponent,
    MultiSelectFilterListComponent,
    MultiSelectLanguagesComponent,
    MultiSelectUniversalComponent,
    RecordNotFoundComponent,
    UniversalDatePickerComponent,
    TextEditorComponent,
    LocalizeDateTimePipe,
    DateMaskDirective,
    ShortLocalizeDateTimePipe,
    YesNoSelectComponent
], imports: [
   ScrollerModule,
    InputNumberModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CheckboxModule,
    ConfirmDialogModule,
    DividerModule,
    ButtonModule,
    SplitButtonModule,
    RadioButtonModule,
    MenubarModule,
    ListboxModule,
    TableModule,
    DropdownModule,
    ProgressSpinnerModule,
    BadgeModule,
    PanelModule,
    ToolbarModule,
    CardModule,
    CommonModule,
    MultiSelectModule,
    SliderModule,
    InputMaskModule,
    CalendarModule,
    TabViewModule,
    FileUploadModule,
    PickListModule,
    PaginatorModule,
    WebcamModule,
    OverlayPanelModule,
    HighchartsChartModule,
    InputSwitchModule,
    AutoCompleteModule,
    DialogModule,
    NgScrollbarModule,
    GoogleMapsModule,
    TimelineModule,
    PanelMenu,
    // OrganizationChartModule,
    TreeModule,
    FocusTrapModule,
    NgxPhotoEditorModule,
    NgOptimizedImage,
    SelectButtonModule,
    NgxCurrencyDirective,
    EditorModule,
    BadgeModule, CommonModule, ToastModule, MessagesModule, RouterModule, AccordionModule,
    DropdownModule, FormsModule, ButtonModule, TooltipModule, NgOptimizedImage, AutoCompleteModule,
    StepsModule], providers: [
    DialogService,
    DynamicDialogConfig,
    ConvertAuxFiltersUtility,
    DynamicDialogRef,
    RosterDefinitions,
    RosterStudentDefinitions,
    ProspectDefinitions,
    PhoneValidationUtility,
    PhonePipe,
    DatePipe,
    CurrencyPipe,
    RouteContextUtility,
    ConfirmValidationUtility,
    DateRangePickerDefinitions,
    ExemptUsersDefinitions,
    ToFixedCurrencyPipe,
    CamelcaseSpacePipe,
    LocalizeDateTimePipe,
    ShortLocalizeDateTimePipe,
    TenantFilterDefinitions,
    DecimalPipe,
    PercentPipe,
    SnapshotDefinitions,
    InterfaceObjectHelpService,
    InterfaceObjectsService,
    LookupsService,
    HttpClient,
    ShortLocalizeDateTimePipe,
    IndexOfPipe,
    DialogUtilityComponent,
    SidenavService,
    AlertsService,
    MessageModule,
    SelectModule,
    TextareaModule,
    YesNoSelectComponent,
    AuthService, ConfirmationService, HttpCacheService, BlobStorageService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiInterceptor,
        multi: true,
    },
    { provide: 'Window', useFactory: () => window },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
] })
export class SharedImports {
}
