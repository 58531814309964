<ng-container>
  @if (contextService.contextObject.accessToken) {
    <app-header [showMenuSelect]="ShowMenuSelect"
                [parentShowBurger]="parentShowBurger"
                (MenuViewSelect)="menuViewSelect($event)" [ngClass]="{'mobile-nav' : isMobile}"
    ></app-header>
  }
  <!--  <ng-sidebar-container #appSelect [ngStyle]="{'height': opened ? 'calc(100% - 86px)' : (!dataPerspectiveDescription && !isPublic ? 'calc(100% - 104px)' : (isPublic ? 'calc(100%)' : 'calc(100% - 123px)'))}">-->
  <div #appSelect class="ng-sidebar-container">
    @if (contextService.contextObject.accessToken && opened) {
        <div class="custom-sidebar" >
          <app-side-nav [selectedMenuView]="selectedMenuView"></app-side-nav>
        </div>
<!--      <p-sidebar class="alertSidebar" position="right" [(visible)]="isAlertsOpened" [modal]="!isMobile"-->
<!--                 (onHide)="isAlertsOpened = false; sidenavService.updateAlertSideBar()">-->
<!--        <app-alerts-sidebar (close)="closeAlerts()"></app-alerts-sidebar>-->
<!--      </p-sidebar>-->
    }
    <div ng-sidebar-content [className]="'ng-sidebar-content'" [ngClass]="{'mobile-wrapper' : isMobile}"
         [ngStyle]="{'padding-left': (opened && !isMobile) ? '240px' : '0px'}">

          <router-outlet class="content"></router-outlet>
    </div>
  </div>
</ng-container>
@if (isAuthenticated) {
  <footer id="footer" [ngStyle]="{'padding-left': (opened && !isMobile) ? '250px' : '5px'}">
    <div class="menu-container">
      <a id="aUniontrack" class="ut-navigation ut-saveexclude" href="https://www.uniontrack.com"
         rel="noopener noreferrer" target="_blank" title="UnionTrack, Inc">&copy; {{ copyYear }},
        UnionTrack<sup>&reg;</sup></a>
      <div style="float: right; margin-bottom: 5px; color: #444">
        Required
        <sup class="req">*</sup>
      </div>
    </div>
  </footer>
} @else {
<!--  <footer class="full-width text-align-middle unauthFooterContainer">-->
<!--    <a class="ut-navigation unauthFooter" href="https://www.uniontrack.com"-->
<!--       rel="noopener noreferrer" target="_blank" title="UnionTrack, Inc">&copy; {{ copyYear }},-->
<!--      UnionTrack<sup>&reg;</sup></a>-->
<!--  </footer>-->
}
<p-confirmDialog [baseZIndex]="19000"></p-confirmDialog>
<p-confirmDialog [baseZIndex]="10000" key="validationFailed"></p-confirmDialog>
<p-confirmDialog [baseZIndex]="10000" key="suspension"></p-confirmDialog>
<div class="form-changed-dialog">
  <p-confirmDialog icon="pi pi-exclamation-triangle" key="formChanged"
                   [blockScroll]="false" [maskStyleClass]="'custom-mask-style'"></p-confirmDialog>
</div>
