import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SharedImports} from '@shared/shared-imports';
import {AppComponent} from '@app/app.component';
import {MenuitemComponent} from '@app/core/components/side-nav/menuitem.component';
import {MenuService} from '@services/menu.service';
import {Subject, Subscription} from 'rxjs';
import {ContextService} from '@services/context.service';
import {TenantModelList} from '@app/models/tenant.model';
import {TenantService} from '@services/tenant-service.service';
import {Router} from '@angular/router';
import menuNoTenant from '@app/json/menu-no-tenant.json';
import menu from '@app/json/menu.json';



@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [SharedImports, MenuitemComponent],
  templateUrl: 'menu.component.html',
  providers: [MenuService],
})



export class MenuComponent implements OnInit, OnChanges {
  @Input() activeMenu: any[];
  menuClick: boolean;
  setTenantId: number = 10000;
  active: boolean;
  activeTenant: Subject<any> = new Subject();
  tenantList: TenantModelList[];
  menuSourceSubscription: Subscription;
  menuResetSubscription: Subscription;
  activeTenantLogo: string = '../assets/images/secondary-logo.png';
  activeTenantLogoUrl: string;
  activeTenantName: string;
  key: string;

    constructor(public contextService: ContextService, private tenantService: TenantService,
                private router: Router, private menuService: MenuService) {}

    ngOnInit() {
      if(this.contextService.contextObject.selectedTenant && this.contextService.contextObject.selectedTenant !== undefined) {
        this.activeMenu = menu;
      } else {
        this.activeMenu = menuNoTenant;
      }

      this.menuSourceSubscription = this.menuService.menuSource$.subscribe({
        next: (key) => {
          // deactivate current active menu
          if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
            this.active = false;
          }
        }
      });

      this.initTenants(false);
    }

    ngOnChanges(changes: SimpleChanges) {
    }

  onMenuClick($event) {
    this.menuClick = true;
  }

  initTenants(disable: boolean): void {
    this.tenantService.getTenantsOptions().subscribe({
      next: (data) => {
        this.tenantList = data;
        this.tenantList.unshift({ID: 1000, Description: '--', Active: true, Enum: null, Rank:0});
        if(this.contextService.contextObject.selectedTenant) {
          this.setTenantId = this.contextService.contextObject.selectedTenant;
        }
      }
    });

  }

  setTenantData(event): void {
    const context = this.contextService.contextObject;
    console.log(event);
    if (event.value !== 10000) {
      context.selectedTenant = event.value;
      // this.activeTenantLogo = this.tenantList.find(tenant => tenant.id === event.value).logoUrl;
      // this.activeTenantName = this.tenantList.find(tenant => tenant.id === event.value).description.toLowerCase();
      context.tenantName = this.tenantList.find(x => x.ID === event.value).Description;
      this.activeTenantLogoUrl = `url("${this.activeTenantLogo}")`;
      this.activeMenu = menu;
      this.router.navigateByUrl('tenant-dashboard/' + event.value);
    } else {
      this.activeMenu = menuNoTenant;
      context.selectedTenant = null;
      this.contextService.contextObject.tenantName = null;
      this.activeTenantLogo = '../assets/images/secondary-logo.png';
      this.activeTenantLogoUrl = null;

    }
    this.contextService.contextObject = context;
  }

  itemClick(event: Event, item) {
    // avoid processing disabled items

    if (item.disabled) {
      event.preventDefault();
      return;
    }

    // navigate with hover in horizontal mode


    // notify other items
    this.menuService.onMenuStateChange(this.key);

    // execute command
    if (item.command) {
      item.command({originalEvent: event, item});
    }

    // toggle active state
    if (item.items) {
      this.active = !this.active;
    } else {
      // activate item
      this.active = true;


    }
    if (item.tenantRequired) {
      console.log(item.routerLink[0] + '/' + this.contextService.contextObject.selectedTenant);
      this.router.navigateByUrl(item.routerLink[0] + '/' + this.contextService.contextObject.selectedTenant);
    } else {
      if (item.routerLink?.length > 0) {
        this.router.navigateByUrl(item.routerLink[0]);
      }

    }
  }

}
