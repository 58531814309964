import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';

import { InterfaceObjectsModel, InterfaceObjectsFlatModel, InterfaceObjectAppsModel, MenuItems } from '../models/interface-objects.model';
import { LookupModel } from '../models/lookups.model';

@Injectable({
  providedIn: 'root',
})
export class InterfaceObjectsService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getAppList(): Observable<InterfaceObjectsModel[]> {
    return this.http.get<InterfaceObjectsModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/getapplist?showall=true`);
  }

  getMenuItems(): Observable<MenuItems[]> {
    return this.http.get<MenuItems[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/getmenu`);
  }

  getAppListFlat(): Observable<InterfaceObjectsFlatModel> {
    return this.http.get<InterfaceObjectsFlatModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/getapplist?flat=true&showall=true`);
  }

  getAppListByContext(context: string): Observable<InterfaceObjectsModel> {
    return this.http.get<InterfaceObjectsModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/getapplist/bycontext/${context}`);
  }

  getAppListByContextFlat(context: string): Observable<InterfaceObjectsFlatModel> {
    return this.http.get<InterfaceObjectsFlatModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/getapplist/bycontext/${context}?flat=true`);
  }

  getObjectListByApp(interfaceObjectEnum: string): Observable<InterfaceObjectAppsModel[]> {
    return this.http.get<InterfaceObjectAppsModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/interface/getobjectlist/${interfaceObjectEnum}?context=${this.contextService.contextObject.selContext}`);
  }

  getInterfacePages(): Observable<LookupModel> {
    return this.http.get<LookupModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/interfacepages`);
  }

  getInterfaceElementsByPageId(interfaceObjectId: number): Observable<LookupModel> {
    return this.http.get<LookupModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/interfaceelementsbypageid/${interfaceObjectId}`);
  }
}
