import {Injectable} from '@angular/core';
import {TenantConfigDataService} from '@services/tenant-config-data.service';

@Injectable()
export class RosterStudentDefinitions {
  rosterStudentCols: any[];

  constructor(private tenantConfigDataService: TenantConfigDataService) {
    // const SECUREDOB = this.tenantConfigDataService.getBooleanValue('SECUREDOB');
    const STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');
    // const SECUREDOBField = (SECUREDOB === true) ? 'DOBSecure_D' : 'DOB_D';
    this.rosterStudentCols = [
      {field: 'ThumbnailUrl', header: 'Profile', fullHeader: 'Profile', columnType: 'text', matchMode: 'contains', visible: true},
      {field: 'Prefix', header: 'Prefix', fullHeader: 'Prefix', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'MiddleName', header: 'Middle Name', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Gender', header: 'Gender', fullHeader: 'Gender', columnType: 'text', matchMode: 'contains', visible: false},
      // {field: SECUREDOBField, header: 'Birth Date', fullHeader: 'Birth Date', columnType: 'date', matchMode: 'equals', visible: true},
      {field: 'StudentNumber', header: 'Student #', fullHeader: 'Student #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'MemberStatusAlias', header: 'Membership Status', fullHeader: 'Membership Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'MemberTypeAlias', header: 'Membership ' + STRINGREPLACETYPE, fullHeader: 'Membership ' + STRINGREPLACETYPE, columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizationName', header: 'Organization', fullHeader: 'Organization', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'UTStatusDescription', header: 'Launch Status', fullHeader: 'Launch Status', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'StatusAlias', header: 'Training Status', fullHeader: 'Training Status', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'TypeAlias', header: 'Training Type', fullHeader: 'Training Type', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Classification', header: 'Classification', fullHeader: 'Classification', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Positions', header: 'Positions', fullHeader: 'Positions', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Instructor', header: 'Instructor', fullHeader: 'Instructor', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Ethnicity', header: 'Ethnicity', fullHeader: 'Ethnicity', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Race', header: 'Race', fullHeader: 'Race', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'MilitaryStatus', header: 'Military Status', fullHeader: 'Military Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'StudentGroupDescription', header: 'Student Group', fullHeader: 'Student Group', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'StudentGroupAlias', header: 'Student Group Alias', fullHeader: 'Student Group Alias', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryAddress1', header: 'Street Address', fullHeader: 'Street Address', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryAddress2', header: 'Ste, Bldg, Flr, etc.', fullHeader: 'Ste, Bldg, Flr, etc.', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryCity', header: 'City/Town', fullHeader: 'City/Town', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryStateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryZip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryCounty', header: 'County', fullHeader: 'County', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryCountry', header: 'Country', fullHeader: 'Country', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryPhoneNumber', header: 'Home Phone #', fullHeader: 'Home Phone #', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'SecondaryPhoneNumber', header: 'Mobile Phone #', fullHeader: 'Mobile Phone #', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PublicPhoneNumber', header: 'Public Phone #', fullHeader: 'Public Phone #', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryEmailAddress', header: 'Primary E-mail', fullHeader: 'Primary E-mail', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PrimaryEmailFailedCommunication', header: 'Primary E-mail Undeliverable', fullHeader: 'Primary E-mail Undeliverable', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'SecondaryEmailAddress', header: 'Secondary E-mail', fullHeader: 'Secondary E-mail', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'SecondaryEmailFailedCommunication', header: 'Secondary E-mail Undeliverable', fullHeader: 'Secondary E-mail Undeliverable', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PublicEmailAddress', header: 'Public E-mail', fullHeader: 'Public E-mail', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'PublicEmailFailedCommunication', header: 'Public E-mail Undeliverable', fullHeader: 'Public E-mail Undeliverable', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'User Account', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true}
    ];
  }
}
