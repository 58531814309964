import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import {RosterGridModel, RosterListModel} from '../models/roster.model';


@Injectable({
  providedIn: 'root',
})
export class RosterService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  getRosterPrimeNG(dataMode: string, filterData, rosterQueryId?: number, routeOrganizationId?: number): Observable<RosterGridModel> {
    const rq = (rosterQueryId) ? rosterQueryId : 0;
    const url = (routeOrganizationId && routeOrganizationId !== this.contextService.contextObject.RootMemberOrgID) ? `&routeorganizationid=${routeOrganizationId}` : '';
    return this.http.post<RosterGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/detailedroster/grid/${rq}?mode=${dataMode}&perspectiveContext=MEMBERSHIP${url}`, filterData);
  }

  getRosterLitePrimeNG(dataMode: string, filterData, rosterQueryId?: number, routeOrganizationId?: number, globalView?: boolean): Observable<RosterListModel> {
    const rq = (rosterQueryId) ? rosterQueryId : 0;
    const url = (routeOrganizationId && routeOrganizationId !== this.contextService.contextObject.RootMemberOrgID) ? `&routeorganizationid=${routeOrganizationId}` : '';
    const global = (globalView === true) ? '&showall=true' : '';
    return this.http.post<RosterListModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/literoster/grid/${rq}?mode=${dataMode}&perspectiveContext=MEMBERSHIP${url}${global}`, filterData);
  }
}
