import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import {TenantModel, TenantModelGroup, TenantModelList} from '../models/tenant.model';

@Injectable({
    providedIn: 'root',
})
export class TenantService {
    constructor(private http: HttpClient, private contextService: ContextService) {
    }

    getTenantsOptions(): Observable<TenantModelList[]> {
        return this.http.get<TenantModelList[]>(`${this.contextService.contextObject.apiBaseUrlV2}/admin/tenant/options`);
    }

  getTenantsList(filterData): Observable<TenantModel[]> {
    return this.http.post<TenantModel[]>(`${this.contextService.contextObject.apiBaseUrlV2}/admin/tenant/prime`, filterData);
  }
}
