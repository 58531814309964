import {Injectable} from '@angular/core';
import {TenantConfigDataService} from '@services/tenant-config-data.service';
import {SnapshotDataModel} from '@app/models/snapshot.model';

@Injectable()
export class SnapshotDefinitions {
  public studentRoster: SnapshotDataModel[] = [];

  constructor(private tenantConfigDataService: TenantConfigDataService) {
    this.studentRoster = [
      {
        ID: 0,
        key: '0',
        label: 'Details',
        data: 'Details',
        icon: 'fa-solid fa-memo-circle-info',
        Permissions: 'tabDemographics',
        Type: 'Summary',
        children: [
          {
            ID: 1,
            key: '0-0',
            label: 'Contact',
            data: 'Contact',
            icon: 'pi pi-fw pi-phone',
            Permissions: 'tabDemographics',
            Type: 'Summary',
            children: []
          },
          {
            ID: 2,
            key: '0-1',
            label: 'Demographics',
            data: 'Demographics',
            icon: 'fa-solid fa-earth-americas',
            Permissions: 'tabDemographics',
            Type: 'Summary',
            children: []
          },
          {
            ID: 3,
            key: '0-2',
            label: 'Emergency Contacts',
            data: 'Emergency Contacts',
            icon: 'fa-solid fa-address-book',
            Permissions: 'tabDemographics',
            Type: 'List',
            children: []
          }
        ]
      }, {
        ID: 4,
        key: '1',
        label: 'Membership',
        data: 'Membership',
        icon: 'fa-solid fa-id-card-clip',
        Permissions: 'tabMembership',
        Type: 'Summary',
        children: []
      }, {
        ID: 5,
        key: '2',
        label: 'Secure Data',
        data: 'Secure Data',
        icon: 'fa-solid fa-lock',
        Permissions: 'tabPii',
        Type: 'Summary',
        children: []
      }, {
        ID: 6,
        key: '3',
        label: 'Student',
        data: 'Student',
        icon: 'fas fa-graduation-cap',
        Permissions: 'tabStudent',
        Type: 'Summary',
        children: []
      }, {
        ID: 7,
        key: '4',
        label: 'Programs',
        data: 'Programs',
        icon: 'fas fa-landmark',
        Permissions: 'tabPrograms',
        Type: 'Summary',
        children: [
          {
            ID: 8,
            key: '4-0',
            label: 'Programs',
            data: 'Programs',
            icon: 'fas fa-landmark',
            Permissions: 'tabPrograms',
            Type: 'SummaryList',
            children: []
          },
          {
            ID: 9,
            key: '4-1',
            label: 'Wage Rates',
            data: 'Wage Rates',
            Type: 'List',
            Permissions: 'tabPrograms',
            icon: 'fa-solid fa-square-dollar',
            children: []
          }
        ]
      }, {
        ID: 10,
        key: '5',
        label: 'Training & Certificates',
        data: 'Training & Certificates',
        icon: 'fas fa-file',
        Permissions: 'tabClassHistory',
        Type: 'Summary',
        children: [
          {
            ID: 11,
            key: '5-0',
            label: 'Classes',
            data: 'Classes',
            icon: 'fas fa-landmark',
            Permissions: 'tabClassHistory',
            Type: 'List',
            children: []
          },
          {
            ID: 12,
            key: '5-1',
            label: 'Certifications',
            data: 'Certifications',
            Type: 'List',
            Permissions: 'tabClassHistory',
            icon: 'fa-solid fa-files',
            children: []
          }
        ],
      }, {
        ID: 13,
        key: '6',
        label: 'Employment',
        data: 'Employment',
        icon: 'fas fa-address-card',
        Permissions: 'tabEmployment',
        Type: 'SummaryList',
        children: [],
      }, {
        ID: 14,
        key: '7',
        label: 'Trades and Skills',
        data: 'Trades and Skills',
        icon: 'fas fa-file',
        Permissions: 'tabTradesSkills',
        Type: 'Summary',
        children: [
          {
            ID: 15,
            key: '7-0',
            label: 'Trades',
            data: 'Trades',
            icon: 'fas fa-paint-roller',
            Permissions: 'tabTradesSkills',
            Type: 'List',
            children: []
          },
          {
            ID: 16,
            key: '7-1',
            label: 'Skills',
            data: 'Skills',
            Type: 'List',
            Permissions: 'tabTradesSkills',
            icon: 'fa-solid fa-files',
            children: []
          }
        ],
      }, {
        ID: 17,
        key: '8',
        label: 'Custom Fields',
        data: 'Custom Fields',
        icon: 'fas fa-user-pen',
        Permissions: 'tabCustomFields',
        Type: 'Summary',
        children: [],
      },
      {
        ID: 18,
        key: '9',
        label: 'Notes',
        data: 'Notes',
        icon: 'fas fa-file',
        Permissions: 'tabNotes',
        Type: 'Summary',
        children: [],
      },
    ];
  }
}
