import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({name: 'toFixedCurrency'})
export class ToFixedCurrencyPipe implements PipeTransform {
  /*
   * @param value - some number
   * @param digits - number of digits after the decimal point
   * @param dir - round up or down (floor/ceil)
   * @returns {string} formatted number with a fixed number of digits after the decimal point
   */
  constructor(public currencyPipe: CurrencyPipe) {}
  transform(value: number, digits: number = 0, dir: string = 'down'): string {
    const round = dir === 'down' ? Math.floor : Math.ceil;
    const rtn: number = round(value * (10 ** digits)) / (10 ** digits);
    return this.currencyPipe.transform(rtn, 'USD','symbol', '1.2-2');
  }
}
