import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {MenuService} from '@services/menu.service';
import {ContextService} from '@services/context.service';
import {GenericSelectModel} from '@app/models/generic-select.model';
import {AppComponent} from '@app/app.component';
import {SharedImports} from '@shared/shared-imports';
import {F} from '@angular/cdk/keycodes';

@Component({
    /* tslint:disable:component-selector */
    selector: 'app-menuitem',
    /* tslint:enable:component-selector */
    templateUrl: './menuitem.component.html',
    providers: [MenuService],
  standalone: true,
  imports: [SharedImports],
    host: {
        '[class.layout-root-menuitem]': 'root || active',
        '[class.active-menuitem]': '(active)'
    },
    animations: [
        trigger('children', [
            state('void', style({
                height: '0px',
                padding: '0px'
            })),
            state('hiddenAnimated', style({
                height: '0px',
                padding: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*'
            })),
            state('hidden', style({
                height: '0px',
                padding: '0px'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('void => visibleAnimated, visibleAnimated => void',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class MenuitemComponent implements OnInit, OnDestroy {
    @Input() item: any;
    @Input() index: number;
    @Input() root: boolean;
    @Input() parentKey: string;
    active = false;
    menuSourceSubscription: Subscription;
    menuResetSubscription: Subscription;
    inputObjTenant: GenericSelectModel;
    setTenantId: number;
    key: string;

    constructor(public app: AppComponent, public router: Router, private cd: ChangeDetectorRef, private menuService: MenuService,
                public contextService: ContextService) {
        this.menuSourceSubscription = this.menuService.menuSource$.subscribe({
            next: (key) => {
                // deactivate current active menu
                if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
                    this.active = false;
                }
            }
        });

        this.menuResetSubscription = this.menuService.resetSource$.subscribe({
            next: () => {
                this.active = false;
            }
        });

    }

    ngOnInit() {

        // if(this.item.routerLink && this.item.routerLink[0].includes(':tenantId')) {
        //     this.item.routerLink[0] = this.item.routerLink[0].replace(':tenantId', this.contextService.contextObject.selectedTenant);
        // }
        this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
        this.initTenants(false);
    }

    updateActiveStateFromRoute() {
        this.active = this.router.isActive(this.item.routerLink[0], !this.item.items && !this.item.preventExact);
    }

    initTenants(disable: boolean): void {
        this.inputObjTenant = {
            labelText: 'Tenant',
            optionValue: 'ID',
            optionLabel: 'Description',
            filter: true,
            requiredField: true,
            selectFirstValue: (!this.setTenantId),
            initSelected: this.setTenantId,
            data: null,
            disabled: disable,
            emitChangeOnLoad: true
        };
        // this.inputObjTenant.data = tenants;
        this.inputObjTenant = Object.assign({}, this.inputObjTenant);
    }

    setTenantData(event): void {
        if (event && event[0]) {
            this.setTenantId = (event[0].ID !== null) ? event[0].ID : -1;
        } else {
            this.setTenantId = -1;
        }
    }



    onMouseEnter() {
        // activate item on hover

    }

    ngOnDestroy() {
        if (this.menuSourceSubscription) {
            this.menuSourceSubscription.unsubscribe();
        }

        if (this.menuResetSubscription) {
            this.menuResetSubscription.unsubscribe();
        }
    }

  protected readonly F = F;
}
